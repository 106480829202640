/* jshint -W030 */
!function ($, window) {
  "use strict";

  // Vars
  var $doc = $(document), $win = $(window), SITE = SITE || {};

  SITE = {
    // Init
    init: function () {
      var obj, self = this;
      for (obj in self) if (self.hasOwnProperty(obj)) {
        var _method = self[obj];
        void 0 !== _method.selector && void 0 !== _method.init && $(_method.selector).length > 0 && _method.init();
      }
    },

    // videoModal
    videoModal: {
      selector: "#videoModal",
      init: function () {
        var base = this, container = $(base.selector), video = $(container).find("iframe"), videoUrl = $(video).attr("src");
        $(container).on('show.bs.modal', function (e) {
          video.attr("src", videoUrl + '?autoplay=1');
        });

        $(container).on('hide.bs.modal', function (e) {
          video.attr("src", videoUrl);
        });
      }
    },

    // Cookie
    cookie: {
      selector: ".eu-cookie-compliance-banner--categories",
      init: function () {
        var base = this, container = $(base.selector);
        setTimeout(function() {
          var btn = container.find(".eu-cookie-compliance-personalize-button");
          var cat = container.find(".eu-cookie-compliance-categories");
          var catInner = container.find(".eu-cookie-compliance-categories__inner");

          btn.on("click", function() {
            if (!cat.hasClass("open")) {
              cat.addClass("open");
              var ContentHeight = catInner.outerHeight(true);
              cat.css("max-height", ContentHeight);
            } else {
              cat.removeClass("open");
              cat.css("max-height", "0");
            }
          });
        }, 1000);
      }
    },

    // Get param
    getParam: {
      selector: "body.node-type-webform #main form",
      init: function () {
        var base = this, container = $(base.selector);

        var serviceParam = getUrlParameter('s');
        if (serviceParam == "5") {
          $("#edit-submitted-service-5").prop("checked", true);
          $("#edit-submitted-service-nl-5").prop("checked", true);
        }

        function getUrlParameter(sParam) {
          var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

          for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
              return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
          }
          return false;
        }
      }
    },

    // Language selector
    languageSelector: {
      selector: "ul.language-switcher-locale-url",
      init: function () {
        var base = this, container = $(base.selector), activeLang = container.find('li.active a').text();

        // create span with active lang
        container.parent().prepend('<span class="label">' + activeLang + '<i class="fa fa-caret-down" aria-hidden="true"></i></span>');
        // hide  active lang in ul
        container.find('li.active').remove();

        //show ul
        container.parent().find('.label').on('click', function () {
          container.slideToggle(350);
          $(this).find('.fa').toggleClass('fa-caret-down');
          $(this).find('.fa').toggleClass('fa-caret-up');
        });

        //hide ul
        $doc.on('click', function (e) {
          var $clicked = $(e.target);
          if (!$clicked.parents().hasClass("languages")) {
            container.slideUp(350);
            container.parent().find('.label').find('.fa').removeClass('fa-caret-up');
            container.parent().find('.label').find('.fa').addClass('fa-caret-down');
          }
        });

        $doc.scroll(function () {
          if ($doc.scrollTop() >= 50) {
            container.slideUp(350);
            container.parent().find('.label').find('.fa').removeClass('fa-caret-up');
            container.parent().find('.label').find('.fa').addClass('fa-caret-down');
          }
        });
      }
    },

    // Share
    share: {
      selector: ".share .trigger-share",
      init: function () {
        var base = this, container = $(base.selector);

        //show ul
        container.on('click', function (e) {
          e.preventDefault();
          container.addClass('active');
          $(this).next('.addthis_toolbox').slideToggle(350);
        });

        //hide ul
        $doc.on('click', function (e) {
          var $clicked = $(e.target);
          if (!$clicked.parents().hasClass("share")) {
            container.removeClass('active');
            container.next('.addthis_toolbox').slideUp(350);
          }
        });

        $doc.scroll(function () {
          if ($doc.scrollTop() >= 50) {
            container.removeClass('active');
            container.next('.addthis_toolbox').slideUp(350);
          }
        });
      }
    },

    // Search
    search: {
      selector: ".search .trigger-search",
      init: function () {
        var base = this, container = $(base.selector);

        //show ul
        container.on('click', function (e) {
          e.preventDefault();
          $(this).next('.searchBox').slideToggle(350);
        });

        //hide ul
        $doc.on('click', function (e) {
          var $clicked = $(e.target);
          if (!$clicked.parents().hasClass("search")) {
            container.next('.searchBox').slideUp(350);
          }
        });

        $doc.scroll(function () {
          if ($doc.scrollTop() >= 50) {
            container.next('.searchBox').slideUp(350);
          }
        });
      }
    },

    //Burger menu
    BurgerMenu: {
      selector: ".trigger-menu",
      init: function () {
        var base = this, container = $(base.selector);

        container.on('click', function (e) {
          e.preventDefault();
          container.toggleClass('open');
          $('body').toggleClass('open-menu');
        });
      }
    },

    ShopTriggerSelect: {
      selector: "#cat-shop select",
      init: function () {
        var base = this, container = $(base.selector);

        container.on('change', function () {
          console.log("hello");
          $("#cat-shop form").submit();
        });
      }
    },

    preventDoubleSending: {
      selector: "#anpi-order-transfer-form, #anpi-order-bancontact-form, #anpi-order-card-form",
      init: function () {
        var base = this, container = $(base.selector);

        container.find("#edit-submit").on('click', function () {
          $(this).attr('disabled', 'disabled');
          container.submit();
        });
      }
    },

    //TabsScroll
    TabsScroll: {
      selector: ".node-type-service",
      init: function () {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          $('#services .wrapper .aside ul li a').click(function () {
            $('html, body').animate({
              scrollTop: $("#services .wrapper .content").offset().top - 80
            }, 800);
          });
        }

      }
    },

    // Header after scroll
    /*NavScrolled: {
      selector: "#header",
      init: function() {
        var base = this, container = $(base.selector);

        var lastScrollTop = 0;
        $win.scroll(function(event){
           var st = $(this).scrollTop();
           if (st > lastScrollTop){
               container.addClass('scrolled');
           } else {
              container.removeClass('scrolled');
           }
           lastScrollTop = st;
        });
      }
    },*/

    // cta fixed after scroll
    ctaScrolled: {
      selector: ".cta-contact, .cta-connexion",
      init: function () {
        var base = this, container = $(base.selector);

        if ($doc.scrollTop() > 70) {
          container.addClass('scrolled');
          $("#header").addClass('scrolled');
        } else {
          container.removeClass('scrolled');
          $("#header").removeClass('scrolled');
        }
      }
    },

    // HP SLIDER
    sliderHp: {
      selector: "#leaderboard .swiper-container",
      init: function () {
        var base = this, container = $(base.selector);

        //initialize swiper when document ready
        var mySwiper = new Swiper('#leaderboard .swiper-container', {
          // Optional parameters
          autoplay: 5000,
          effect: 'fade'
        });

        $(".swiper-pager a").each(function () {
          $(this).on("mouseenter", function () {
            var $index = $(this).data("slider-index");
            mySwiper.slideTo($index, 300, false);
          });
        });
      }
    },

    // products carousel
    productsCarousel: {
      selector: "#eshop-products",
      init: function () {
        var base = this, container = $(base.selector);

        var swiperLogo = new Swiper('#eshop-products .swiper-container', {
          spaceBetween: 10,
          slidesPerView: 4,
          loop: true,
          nextButton: '#eshop-products .swiper-button-next',
          prevButton: '#eshop-products .swiper-button-prev',
          autoplay: 5000,
          breakpoints: {
            767: {
              slidesPerView: 1
            },
            991: {
              slidesPerView: 2
            },
            1199: {
              slidesPerView: 3
            }
          }
        });
      }
    },

    // Logos carousel
    logoCarousel: {
      selector: ".html",
      init: function () {
        var base = this, container = $(base.selector);

        var swiperLogo = new Swiper('#logos .swiperLogo', {
          slidesPerView: 4,
          centeredSlides: false,
          nextButton: '#logos .swiper-button-next',
          prevButton: '#logos .swiper-button-prev',
          paginationClickable: true,
          spaceBetween: 0,
          loop: true,
          autoplay: 1500,
          grabCursor: true,
          breakpoints: {
            // when window width is <= 320px
            320: {
              slidesPerView: 1,
              spaceBetween: 0
            },
            // when window width is <= 480px
            480: {
              slidesPerView: 2,
            },
            // when window width is <= 640px
            640: {
              slidesPerView: 3,
            }
          }
        });

        $("#logos .swiperLogo .item").hover(function () {
          swiperLogo.stopAutoplay();
        }, function () {
          swiperLogo.startAutoplay();
        });

      }
    },

    // Fade
    fadeScroll: {
      selector: ".os-animation",
      init: function () {

        function onScrollInit(items, trigger) {
          items.each(function () {
            var osElement = $(this),
              osAnimationClass = osElement.attr('data-os-animation'),
              osAnimationDelay = osElement.attr('data-os-animation-delay');

            osElement.css({
              '-webkit-animation-delay': osAnimationDelay,
              '-moz-animation-delay': osAnimationDelay,
              'animation-delay': osAnimationDelay
            });

            var osTrigger = (trigger) ? trigger : osElement;

            osTrigger.waypoint(function () {
              osElement.addClass('animated').addClass(osAnimationClass);
            }, {
              triggerOnce: true,
              offset: '70%'
            });
          });
        }
        onScrollInit($('.os-animation'));

      }
    },

    // Expand categories page services
    expandCat: {
      selector: "#services ul.cat2 .expand",
      init: function () {
        var base = this, container = $(base.selector);

        $doc.on("click", "#services ul.cat2 .expand", function (e) {
          e.preventDefault();
          $(this).parent("li").toggleClass("open");
          $(this).next("ul").slideToggle(200);
        });
      }
    },

    // Example
    /*myFunction: {
      selector: "#selector",
      init: function() {
        var base = this, container = $(base.selector);
        container.each(function() {
          var that = $(this);
        });
      }
    },*/

    // Resize
  }, $win.resize(function () {

    // Scroll
  }), $win.scroll(function () {
    SITE.ctaScrolled.init();

    // Ready
  }), $doc.ready(function () {
    SITE.init();
  });
}(jQuery, this);
